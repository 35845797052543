<div>
	<form enctype="application/x-www-form-urlencoded">
		<div class="form-group">
			<i class="input-icon mdi mdi-key-variant"></i>
			<input class="form-control"
				   type="text"
				   name="username"
				   autocomplete="off"
				   placeholder="{{'ENTER_MFA_TOKEN' | translate}}"
				   (keyup)="checkErrorState()"
				   [(ngModel)]="otpValue"
				   required />
		</div>
		<label class="checkbox-container keep-me-authenticated__label">
			<input type="checkbox" class="form-control"  [(ngModel)]="auth2FADays"
				   [ngModelOptions]="{standalone: true}">
			<span class="checkmark"></span>
			<span>{{'KEEP_ME_AUTHENTICATED' | translate}}</span>
		</label>
		<div class="form-group" *ngIf="useGoogleCaptcha">
			<div class="mb-1 g-recaptcha-container reset-password" [innerHtml]="captchaHtml">

			</div>
			<div class="loader-container d-flex flex-justify-content-center" *ngIf="isLoadingCaptcha">
				<div class="loader"></div>
			</div>
		</div>
		<div matTooltip="{{getErrorMessage()}}"
			 matTooltipPosition="above"
			 [matTooltipDisabled]="!isErrorState">
			<button
				[csLoader]="isWaitingForReset"
				[loaderAdditionalClasses]="['loader--white']"
				[disabled]="isErrorState"
				(click)="verifyOtp()"
				class="btn btn--primary btn--lg">
				{{'VERIFY_CODE' | translate | titlecase}}
			</button>
		</div>
		<div>
			<div class="mb-3 mt-3 f-body-s f-color-dimmed">{{ "OR" | translate}}</div>

			<button class="btn btn--icon-and-text btn--lg auth-provider-button text-truncate text-no-whitespace"
			(click)="requestNewEmail()">
				<i class="icon icon-cohelion" style="margin-top: -2px"></i>
				<div class="full-width">{{ "EMAIL_NEW_CODE" | translate }}</div>
			</button>
		</div>

	</form>
	<p class="card-text login-footer-text">
		{{'IF_YOU_HAVE_PROBLEMS_PERSIST' | translate | capitalize}}
		<a href="mailto:{{loginConfig.supportEmail}}"
		   class="link">{{loginConfig.supportEmail}}</a>
	</p>
	<div class="login-form--bottom-bar" >
		<a (click)="goBackOfLogout()" class="link font-weight-bold">
			{{'CANCEL_LOGIN_MESSAGE' | translate | capitalize}}</a>
	</div>
</div>
