import { EventEmitter, Injector }                         from '@angular/core';
import { MatDialog }                                      from '@angular/material/dialog';
import { ActivatedRoute }                                 from '@angular/router';
import { WaitingForResponse }                             from '@cs/common/utils';
import {
	DataEntrySaveMethod,
	SheetActionEventArgs
}                                                         from '@cs/components/data-grid';
import { FilterCompareBarQuery, FilterCompareBarService } from '@cs/components/filter-and-compare-bar';
import { IButton }                                        from '@cs/components/shared';
import { CsToastManagerService }                          from '@cs/components/toast-manager';
import { createToObjectWithLowerCaseKeys }                from '@cs/components/util';
import {
	getErrorMessagesFromValidators,
	Result
}                                                         from '@cs/core';
import { isNullOrUndefined }                              from '@cs/core/utils';
import {
	DataEntryComponent, DataEntryResultParams, DataEntryStateQuery, DataEntryStateService, DataGridAddMemberDialogComponent,
	ImportExportAgent
}                                                         from '@cs/performance-manager/data-entry';
import {
	ActionButtonResult, BottomBarQuery, BottomBarRequestActions,
	BottomBarService, CsButtonBarComponent, DynamicButtonBarClickedEventArgs,
	DynamicButtonRegistration, DynamicButtonsAgent
}                                                         from '@cs/performance-manager/shared';
import { getUIPlaceholder, isUIPlaceholder }               from '@cs/common/culture';
import { TranslateService }                               from '@ngx-translate/core';
import { tap }                                            from 'rxjs/operators';


export class DataEntryDynamicPageButtonsAgent extends DynamicButtonsAgent {

	setupButtons(): void {

		this.addButton(DynamicButtonRegistration.createClientButton({
																		buttonId:      'ChartToggle',
																		buttons:       [
																			{
																				label:     'collapse',
																				name:      'collapse',
																				iconClass: 'mdi mdi-arrow-collapse mr-1'
																			}, {
																				label:     'open',
																				name:      'open',
																				iconClass: 'mdi mdi-arrow-expand mr-1'
																			}
																		],
																		buttonsStates: [
																			{
																				from:              'collapse',
																				to:                'open',
																				shouldExecute:     (dynamicButtonName: string) => {
																					return dynamicButtonName === 'collapse';
																				},
																				isVisible:         (button: DynamicButtonRegistration,
																									injector: Injector, pageComponent: unknown) => {
																					const dataEntryStateService = injector.get(DataEntryStateQuery);
																					return dataEntryStateService.getValue().showChart;
																				},
																				executeTransition: (button: DynamicButtonRegistration,
																									injector: Injector, pageComponent: unknown) => {
																					const dataEntryStateService = injector.get(DataEntryStateService);
																					dataEntryStateService.toggleChartPanelVisibility();
																				}
																			},
																			{
																				from:              'open',
																				to:                'collapse',
																				isVisible:         (button: DynamicButtonRegistration,
																									injector: Injector, pageComponent: unknown) => {
																					const dataEntryStateService = injector.get(DataEntryStateQuery);
																					return dataEntryStateService.getValue().showChart;
																				},
																				shouldExecute:     (dynamicButtonName: string) => {
																					return dynamicButtonName === 'open';
																				},
																				executeTransition: (button: DynamicButtonRegistration,
																									injector: Injector, pageComponent: unknown) => {
																					const dataEntryStateService = injector.get(DataEntryStateService);
																					dataEntryStateService.toggleChartPanelVisibility();
																				}
																			}
																		]
																	}));


		this.addButton(DynamicButtonRegistration.createClientButton({
																		buttonId:      'Save',
																		buttons:       [
																			{
																				label:     'Save',
																				name:      'save',
																				btnClass:  'btn--primary btn-md m-0',
																				iconClass: 'mdi mdi-content-save'
																			}
																		],
																		isDisabled:    (button: DynamicButtonRegistration, injector: Injector,
																						component: CsButtonBarComponent) => {
																			const buttonBarQuery = injector.get(BottomBarQuery);

																			const errors    = getErrorMessagesFromValidators(component.reasonField,
																															 buttonBarQuery.getValue().inputValidators);
																			button.messages = errors.map(value => value.errorMessage);

																			return (!buttonBarQuery.getValue().dataIsValid)
																				|| (component.hasInputOptions && (component.reasonField.invalid
																					|| (buttonBarQuery.getValue().inputRequired && component.reasonField.pristine)));
																		},
																		buttonsStates: [
																			{
																				from:              'save',
																				to:                'save',
																				executeTransition: (button: DynamicButtonRegistration,
																									injector: Injector, pageComponent: unknown) => {
																					const bottomBarService       = injector.get(BottomBarService);
																					const onDynamicButtonClicked = new EventEmitter<DynamicButtonBarClickedEventArgs>();

																					onDynamicButtonClicked.emit(
																						new DynamicButtonBarClickedEventArgs(button));
																					bottomBarService.onButtonClicked.next(
																						new DynamicButtonBarClickedEventArgs(button));
																				},
																				isVisible:         (button: DynamicButtonRegistration,
																									injector: Injector, pageComponent: unknown) => {
																					const buttonBarQuery = injector.get(BottomBarQuery);
																					const dataEntryStateService = injector.get(DataEntryStateQuery);
																					return (dataEntryStateService.getValue().saveMethod === DataEntrySaveMethod.TopButtonBar) &&
																					(buttonBarQuery.getValue().unsavedChanges > 0);
																				}
																			}
																		]
																	}));

		this.addButton(DynamicButtonRegistration.createClientButton({
																		buttonId:      'Cancel',
																		buttons:       [
																			{
																				label:     'Cancel',
																				name:      'cancel',
																				btnClass:  'btn--quaternary btn-md m-0 mr-2',
																				iconClass: 'mdi mdi-close'
																			}
																		],
																		buttonsStates: [
																			{
																				from:              'cancel',
																				to:                'cancel',
																				executeTransition: (button: DynamicButtonRegistration,
																									injector: Injector, pageComponent: unknown) => {
																					const bottomBarService       = injector.get(BottomBarService);
																					const onDynamicButtonClicked = new EventEmitter<DynamicButtonBarClickedEventArgs>();

																					onDynamicButtonClicked.emit(
																						new DynamicButtonBarClickedEventArgs(button));
																					bottomBarService.onButtonClicked.next(
																						new DynamicButtonBarClickedEventArgs(button));
																					bottomBarService.requestAction.next(
																						{action: BottomBarRequestActions.RESET_BAR});
																				},
																				isVisible:         (button: DynamicButtonRegistration,
																									injector: Injector, pageComponent: unknown) => {
																					const buttonBarQuery = injector.get(BottomBarQuery);
																					const dataEntryStateService = injector.get(DataEntryStateQuery);
																					return dataEntryStateService.getValue().saveMethod === DataEntrySaveMethod.TopButtonBar &&
																					(buttonBarQuery.getValue().unsavedChanges > 0);
																				}
																			}
																		]
																	}));


		// this.addButton(DynamicButtonRegistration.createClientButton({
		//   buttonId:         'EventsToggle', buttons: [
		//     {
		//       label:     'events',
		//       name:      'showEvents',
		//       iconClass: 'mdi mdi-paperclip mr-1'
		//     },
		//     {
		//       label:     'chart',
		//       name:      'showChart',
		//       iconClass: 'mdi mdi-chart-line mr-1'
		//     }
		//   ], buttonsStates: [
		//     {
		//       from:              'showEvents', to: 'showChart',
		//       shouldExecute:     (dynamicButtonName: string) => {
		//         return dynamicButtonName === 'showEvents';
		//       },
		//       executeTransition: (button: DynamicButtonRegistration, injector: Injector, pageComponent: unknown) => {
		//         const dataEntryStateService = injector.get(DataEntryStateService);
		//         dataEntryStateService.toggleEventsPanelVisibility();
		//       }
		//     },
		//     {
		//       from:              'showChart', to: 'showEvents',
		//       shouldExecute:     (dynamicButtonName: string) => {
		//         return dynamicButtonName === 'showChart';
		//       },
		//       executeTransition: (button: DynamicButtonRegistration, injector: Injector, pageComponent: unknown) => {
		//         const dataEntryStateService = injector.get(DataEntryStateService);
		//         dataEntryStateService.toggleEventsPanelVisibility();
		//       }
		//     }
		//   ]
		// }));

		this.addButton(DynamicButtonRegistration.createServerButton({
																		buttonId:          'Import',
																		executeTransition: (button: DynamicButtonRegistration, injector: Injector,
																							pageComponent: unknown, payload: FileList) => {
																			const filterCompareBarQuery = injector.get(FilterCompareBarQuery);
																			const resultParams          = filterCompareBarQuery.getValue().mainbarResultParams as DataEntryResultParams;
																			const dataEntry             = pageComponent as DataEntryComponent;
																			const clickedBtn            = button.displayInstance as IButton;
																			const gridNameToUse         = clickedBtn.alternativeGridName
																										  ? clickedBtn.alternativeGridName
																										  : dataEntry.dataEntryGrid.gridName;
																			button.isLoading            = true;

																			ImportExportAgent.importButtonClicked(
																								 gridNameToUse,
																								 injector,
																								 dataEntry.config,
																								 clickedBtn,
																								 resultParams.selection,
																								 payload)
																							 .subscribe(value => {
																								 button.isLoading = false;
																								 dataEntry.dataEntryGrid.refreshData();
																								 dataEntry.updateStatsAndChart();
																							 });
																		}
																	}
		));

		this.addButton(DynamicButtonRegistration.createServerButton({
																		buttonId:          'Export',
																		executeTransition: (button: DynamicButtonRegistration, injector: Injector,
																							pageComponent: unknown) => {
																			const filterCompareBarQuery = injector.get(FilterCompareBarQuery);
																			const resultParams          = filterCompareBarQuery.getValue().mainbarResultParams as DataEntryResultParams;
																			const dataEntry             = pageComponent as DataEntryComponent;
																			const clickedBtn            = button.displayInstance as IButton;
																			const gridNameToUse         = clickedBtn.alternativeGridName
																										  ? clickedBtn.alternativeGridName
																										  : dataEntry.dataEntryGrid.gridName;
																			button.isLoading            = true;

																			ImportExportAgent.exportButtonClicked(gridNameToUse,
																												  injector, dataEntry.config,
																												  clickedBtn,
																												  resultParams.selection)
																							 .subscribe(value => {
																								 button.isLoading = false;
																								 dataEntry.detectChanges();
																							 });
																		}
																	}
		));

		this.addButton(DynamicButtonRegistration.createServerButton({
																		buttonId:          'EntryGridButton',
																		executeTransition: async (button: DynamicButtonRegistration,
																								  injector: Injector, pageComponent: unknown) =>
																			DataEntryDynamicPageButtonsAgent.executeTransitionEntryGridButton(button, injector, pageComponent)
																	}
		), true);

		this.addButton(DynamicButtonRegistration.createServerButton({
																		buttonId:          'AddEmptyRowWithDialog',
																		executeTransition: (button: DynamicButtonRegistration, injector: Injector,
																							pageComponent: unknown,
																							payload: SheetActionEventArgs) => {
																			const filterCompareBarQuery = injector.get(FilterCompareBarQuery);
																			const toastService          = injector.get(CsToastManagerService);
																			const dialog                = injector.get(MatDialog);

																			const resultParams = filterCompareBarQuery.getValue().mainbarResultParams as DataEntryResultParams;
																			const dataEntry    = pageComponent as DataEntryComponent;

																			const event       = payload;
																			// get the sheet button by name
																			const sheetButton = event.sheet.settings.dynamicSheetActionButtons.find(
																				item => item.name === event.actionParams.name);
																			if (isNullOrUndefined(sheetButton))
																				throw new Error(
																					`No sheet button with name '{{event.actionParams.name}}' found on sheet.`);


																			// get memberList from button properties
																			const memberList         = !isNullOrUndefined(sheetButton.memberList)
																									   ? sheetButton.memberList
																									   : '';
																			const preselectSheetKeys = !isNullOrUndefined(
																				sheetButton.preselectSheetKeys)
																									   ? sheetButton.preselectSheetKeys
																									   : false;
																			const forNestedgrid      = !isNullOrUndefined(sheetButton.forNestedGrid)
																									   ? sheetButton.forNestedGrid
																									   : false;

																			// prepare dialog data
																			const dialogData = {
																				dialogTitle: 'Add a new row.',
																				memberList:  memberList,
																				keys:        {},
																				gridName:    resultParams.dataEntryGrid,
																				params:      resultParams.params,
																				selection:   resultParams.selection
																			};

																			// preselect and disable the element based on supplied keys
																			// For example: preselect the carrier and allow user to change the aircraft model
																			if (preselectSheetKeys)
																				dialogData.keys = event.sheet.keys;

																			const AddEmptyRowWithDialogRef = dialog.open(
																				DataGridAddMemberDialogComponent, {
																					data:       dialogData,
																					panelClass: [
																						'mat-dialog-rounded-with-close-btn-header',
																						'add-member-panel'
																					]
																				});

																			AddEmptyRowWithDialogRef.afterClosed()
																									.subscribe(result => {
																										if (isNullOrUndefined(
																											result) || isNullOrUndefined(result.data))
																											return;

																										if (forNestedgrid) {
																											alert('This not implemented');
																										} else {
																											dataEntry.dataEntryGrid.addEmptyRow(
																												event.sheet, true,
																												createToObjectWithLowerCaseKeys(
																													result.data));
																										}
																									});
																		}
																	}
		));

		this.setPayloadTypeMapper(new Map<string, string>(
			[
				['Client.RefreshNavBar', 'NavigateTo']
			]
		));

		this.addButton(DynamicButtonRegistration.createServerButton({
																		buttonId:          'NavigateTo',
																		icon:              'mdi mdi-sign-direction',
																		executeTransition: (button: DynamicButtonRegistration,
																							injector: Injector,
																							pageComponent: unknown,
																							payload: SheetActionEventArgs) => {
																			const filterCompareBarService = injector.get(FilterCompareBarService);
																			filterCompareBarService.triggerNavigation(
																				button.displayInstance.payload.navBarParams);
																		}
																	}
		));

	}

	static executeTransitionEntryGridButton(button: DynamicButtonRegistration,
		injector: Injector, pageComponent: unknown) {
		const activatedRoute          = injector.get(ActivatedRoute);
		const filterCompareBarQuery   = injector.get(FilterCompareBarQuery);
		const filterCompareBarService = injector.get(FilterCompareBarService);
		const toastService            = injector.get(CsToastManagerService);
		const i18nApp                 = injector.get(TranslateService, null);

		const resultParams = filterCompareBarQuery.getValue().mainbarResultParams as DataEntryResultParams;
		const dataEntry    = pageComponent as DataEntryComponent;

		// While waiting disable the clicked button to avoid impatient users
		button.disable();

		const gridName  = dataEntry.dataEntryGrid.gridName;
		const selection = resultParams.selection;

		button.confirm(injector)
				.then(confirm => {
					if (!confirm) {
						button.enable();
						return;
					} else {
						dataEntry.config.executeActionButton(gridName,
																button.displayInstance.name,
																selection)
									.pipe(tap(WaitingForResponse.new(
										isLoading => button.isLoading = isLoading)))
									.subscribe(
										(response: Result<ActionButtonResult>) => {

											const result = response.value;

											if (result.success) {
												// check if the button needs to be disabled
												result.disableButton
												? button.disable()
												: button.enable();

												// if button is processed show succes an update data if needed
												if (result.processed) {
													toastService.show({
																		type:    'success',
																		content: isUIPlaceholder(result.message)
																				? getUIPlaceholder(result.message, i18nApp)
																				: result.message

																	});
													if (result.refreshView) {
														filterCompareBarService.triggerNavigation(
															(activatedRoute.snapshot.queryParams));
													}
													if (result.refreshGrid) {
														filterCompareBarService.setSpecialSaveParams(
															(<any>result).applyGridParams);
														dataEntry.refreshAllData();
													}
												} else {
													// nothing changed only notify user
													toastService.show({
																		type:    'warning',
																		content: isUIPlaceholder(result.message)
																				? getUIPlaceholder(result.message, i18nApp)
																				: result.message
																	});
												}
											} else {
												// an error occured enable the button again
												toastService.show({
																	type:    'error',
																	content: isUIPlaceholder(result.message)
																			? getUIPlaceholder(result.message, i18nApp)
																			: result.message
																});
												button.enable();
											}
										});
						}
				});
	}
}
