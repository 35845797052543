import { ChangedDataPackage, IChartSerie, IInitData }                        from '@cs/components/data-grid';
import { ActionButtonResult, IConfigService, CfResult, ImportResultMessage } from '@cs/performance-manager/shared';
import { Observable }                                                        from 'rxjs';
import { CsHttpRequestOptions, Result }                                      from '@cs/core';
import { HasUnconfirmedFacts }                                               from './models/has-unconfirmed-facts.model';
import {
	IDataGridExportConfig,
	IDataGridImportConfig
}                                                                            from './agents/import-export.agent';
import { HttpResponse }                                                      from '@angular/common/http';
import { ReportDataResult }                                                  from './models/report-data-result.model';
import { StatisticsResult }                                                  from './models/statistics-result.model';
import { Params }                                                            from '@angular/router';

import { IAddMemberConfig }  from './components/data-grid-add-member-dialog/add-member-dialog.config';
import { CSAddMemberDialog } from './components/data-grid-add-member-dialog/data-grid-add-member-dialog.models';

export abstract class DataEntryConfigService implements IConfigService,
														IDataGridExportConfig,
														IDataGridImportConfig,
														IAddMemberConfig {
	moduleName              = 'data-entry';
	enableLegacyDataConfirm = false;

	abstract getInitBundle(datagridName: string,
						   selection: {
							   [key: string]: any
						   },
						   params?: {
							   [key: string]: any
						   }): Observable<Result<IInitData>>;

	abstract hasUnconfirmedFacts(datagridName: string,
								 selection: {
									 [key: string]: any
								 },
								 params?: {
									 [key: string]: any
								 },
								 csOptions?: CsHttpRequestOptions): Observable<Result<HasUnconfirmedFacts>>;

	abstract saveFacts(datagridName: string,
					   data: ChangedDataPackage,
					   params?: {
						   [key: string]: any
					   },
					   csOptions?: CsHttpRequestOptions): Observable<Result<CfResult<any>>>;

	abstract getExportForGrid(gridName: string,
							  exportName: string,
							  selection?: {
								  [key: string]: string | number
							  },
							  params?: {
								  [p: string]: any
							  },
							  csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<HttpResponse<Blob>>>;

	abstract uploadImportForGrid(gridName: string,
								 exportName: string,
								 file: File,
								 selection?: {
									 [key: string]: string | number
								 },
								 params?: {
									 [p: string]: any
								 },
								 csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<ImportResultMessage>>;

	abstract getFactTablesReportData(factTableName: string,
									 chartData: IChartSerie,
									 selection: {
										 [key: string]: string | number
									 }): Observable<Result<ReportDataResult>>;

	abstract getStatsData(gridName: string,
						  selection: {
							  [key: string]: string | number
						  },
						  comparisonSelection?: {
							  [key: string]: string | number
						  },
						  params?: {
							  [key: string]: string | number
						  }): Observable<Result<StatisticsResult[]>>;

	abstract confirmFactsLegacy(gridName: string,
								selection: {
									[key: string]: string | number
								},
								params?: {
									[key: string]: string | number
								}): Observable<Result<{
		selection: Params
	}>>;

	abstract executeActionButton(gridName: string,
								 buttonName: string,
								 selection: Params,
								 params?: Params): Observable<Result<ActionButtonResult>>;

	abstract getAddMemberForm(gridName: string, selection: {
								  [p: string]: any
							  },
							  memberList: {
								  [p: string]: any
							  },
							  params?: {
								  [p: string]: any
							  }): Observable<Result<CSAddMemberDialog>>;

	abstract getCommonMembers(): Observable<Result<{
		[p: string]: any
	}>>;

	abstract getExtraMembers(): Observable<Result<{
		[p: string]: any
	}>>;
}
