/**
 * De IJsmaker | Cohelion
 * API documentation for De IJsmaker | Cohelion
 *
 * OpenAPI spec version: 0.0.1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
  HttpResponse,
  HttpEvent
}                                                            from '@angular/common/http';
import { CsHttpClient, CsHttpRequestOptions }                from '@cs/core';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable } from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class NavfilterbarsService {

    protected basePath = ''; // No default basepath
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: CsHttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get the NavFilterBar data
     * Get the NavFilterBar data
     * @param name NavFilterBar name
     * @param isComparison Request data for comparison bar or regular bar
     * @param params Params object as JSON string
     * @param paramsMain Main Params object as JSON string, use this to provide the main params in a request of the comparison bar.
     * @param trigger The name (identifier) of the dropdown that the user interacted with to trigger this api call.
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public navfilterbarsNameDataPost(name: string, isComparison?: boolean, params?: string, paramsMain?: string, trigger?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public navfilterbarsNameDataPost(name: string, isComparison?: boolean, params?: string, paramsMain?: string, trigger?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public navfilterbarsNameDataPost(name: string, isComparison?: boolean, params?: string, paramsMain?: string, trigger?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public navfilterbarsNameDataPost(name: string, isComparison?: boolean, params?: string, paramsMain?: string, trigger?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling navfilterbarsNameDataPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (isComparison !== undefined) {
            formParams = formParams.append('isComparison', <any>isComparison) || formParams;
        }
        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (paramsMain !== undefined) {
            formParams = formParams.append('paramsMain', <any>paramsMain) || formParams;
        }
        if (trigger !== undefined) {
            formParams = formParams.append('trigger', <any>trigger) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<any>(`${this.basePath}/navfilterbars/${encodeURIComponent(String(name))}/data`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

    /**
     * Get the NavFilterBar data
     * Get the NavFilterBar data
     * @param params Params object as JSON string
     * @param searchInput Input to search for
     * @param isComparison Unused, should always be false
     * @param paramsMain Unused
     * @param trigger Trigger for the Level search (Turnarround).
     
     * @param csHttpRequestOptions set extra custom logic for the request, like a override for the default error handling.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public navfilterbarsSearchPost(params: string, searchInput: string, isComparison?: boolean, paramsMain?: string, trigger?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public navfilterbarsSearchPost(params: string, searchInput: string, isComparison?: boolean, paramsMain?: string, trigger?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public navfilterbarsSearchPost(params: string, searchInput: string, isComparison?: boolean, paramsMain?: string, trigger?: string, csHttpRequestOptions?: CsHttpRequestOptions, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public navfilterbarsSearchPost(params: string, searchInput: string, isComparison?: boolean, paramsMain?: string, trigger?: string, csHttpRequestOptions = null, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (params === null || params === undefined) {
            throw new Error('Required parameter params was null or undefined when calling navfilterbarsSearchPost.');
        }
        if (searchInput === null || searchInput === undefined) {
            throw new Error('Required parameter searchInput was null or undefined when calling navfilterbarsSearchPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (isComparison !== undefined) {
            formParams = formParams.append('isComparison', <any>isComparison) || formParams;
        }
        if (params !== undefined) {
            formParams = formParams.append('params', <any>params) || formParams;
        }
        if (paramsMain !== undefined) {
            formParams = formParams.append('paramsMain', <any>paramsMain) || formParams;
        }
        if (trigger !== undefined) {
            formParams = formParams.append('trigger', <any>trigger) || formParams;
        }
        if (searchInput !== undefined) {
            formParams = formParams.append('searchInput', <any>searchInput) || formParams;
        }

        if (csHttpRequestOptions !== null && csHttpRequestOptions.headers !== null) {
              for (const headerKey of csHttpRequestOptions.headers.keys()) {
                headers = headers.append(headerKey, csHttpRequestOptions.headers.get(headerKey));
              }
            }
        return this.httpClient.post<any>(`${this.basePath}/navfilterbars/search`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress,
                csHttpRequestOptions: csHttpRequestOptions
            }
        );
    }

}
