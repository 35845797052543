import {
	AfterViewInit,
	ChangeDetectorRef,
	Component, EventEmitter,
	Input, NgZone, OnChanges,
	OnDestroy, Output,
	QueryList, SimpleChanges,
	ViewChildren
}                                                                               from '@angular/core';
import { ActivatedRoute, Router }                                               from '@angular/router';
import { FilterSelectionChangedEventArgs, SelectionChangedWithSearchEventArgs } from '@cs/components/advanced-dropdown';
import { KeyValuePair }                                                         from '@cs/core/generate';
import { TranslateService }                                                     from '@ngx-translate/core';
import { CompareModeChangedEventArgs }                                          from './event-args/compare-mode-changed-event-args';
import { CompareAndFilterBarDataSource }                                        from './compare-and-filter-bar-data-source';
import { flattenObject, isEmptyObject, isNullOrUndefined }                      from '@cs/core';
import { AdvancedDropdownItem }                                                 from '@cs/components/advanced-dropdown';
import { isNumber, isString }                                                   from '@cs/components/util';
import { CsAdvancedDropdownComponent }                                          from '@cs/components/advanced-dropdown';
import { DropdownOpenStateEventArgs }                                           from '@cs/components/advanced-dropdown';
import { CsDatepicker }                                                         from '@cs/components/datepicker';
import { FilterBarDataSource }                                                  from './models/filter-bar-data-source';
import { FilterCompareBarService }                                              from './state/filter-compare-bar.service';
import { FilterCompareBarStore }                                                from './state/filter-compare-bar.store';
import { FilterBarResultParams }                                                from './models/filter-bar-result-params';
import {
	animate, state, style, transition, trigger
}                                                                               from '@angular/animations';
import { FilterBarItem }                                                        from './models/filter-bar-element';
import { SafeMethods, AppNavigationService }                                    from '@cs/common';
import { take }                                                                 from 'rxjs/operators';


@Component({
												selector:    'cs-filter-and-compare-bar',
												templateUrl: './filter-and-compare-bar.component.html',
												animations:  [
													trigger('toggleCompareBar', [
														state('true',
																				style({
																											height: '220px'
																										})
														),
														state('false',
																				style({
																											height: '*'
																										})
														),
														transition('* <=> *', animate('0.4s cubic-bezier(0.4, 0.0, 0.2, 1)'))
													]),
													trigger('toggleCompareButton', [
														state('true',
																				style({
																											opacity: 1
																										})
														),
														state('false',
																				style({
																											opacity: 0
																										})
														),
														transition('* <=> *', animate('0.4s cubic-bezier(0.4, 0.0, 0.2, 1)'))
													])
													// ,
													// trigger('listAnimation', [
													//   transition('firstLoaded => loading', [ // each time the binding value changes
													//     query('.col', [
													//       style({opacity: 1}),
													//       animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
													//         style({
													//           opacity: .5
													//         }))

													//     ], {optional: true})
													//   ]),
													//   transition('* => remove', [ // each time the binding value changes
													//     query('.col:leave', [
													//       style({opacity: 1}),
													//       stagger(50, [
													//         animate('350ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
													//           style({
													//             opacity: 0
													//           }))
													//       ])
													//     ], {optional: true})
													//   ]),
													//   transition('* => loaded', [ // each time the binding value changes
													//     query('.col:enter', [
													//       style({opacity: 0}),
													//       // group([
													//       stagger(50, [
													//         animate('350ms cubic-bezier(.8, -0.6, 0.2, 1.5)',
													//           style({opacity: 1}))
													//       ])
													//     ], {optional: true})
													//   ])
													// ])
												]
											})
export class CsFilterAndCompareBarComponent implements OnDestroy,
																																																							AfterViewInit,
																																																							OnChanges {


	/**
		* Getter for easier handling of @{CsFilterAndCompareBarComponent.useLocalOnPage}
		* @private
		*/
	private get filterCompareBarService() {
		if (!this.useLocalOnPage)
			return this._filterCompareBarService;
		else
			return new FilterCompareBarService(new FilterCompareBarStore()); // STUB OBJECT
	}


	@Input() mDataSource: FilterBarDataSource<FilterBarResultParams>;
	@Input() cDataSource: FilterBarDataSource<FilterBarResultParams>;
	isLoadingCompareBar: boolean;

	/**
		* Flag to change behaviour. Use dark font and light background when false, Use Light font and dark background when true
		*/
	@Input() invertStyling                                                    = true;
	/**
		* Flag to change behaviour. The filterbar is locally available only on the page. Not triggering application wide events.
		*/
	@Input() useLocalOnPage                                                   = false;
	/**
		* Whether the URL parameters should be updated when the selection changes.
		*/
	@Input() SyncUrlParameters                                                = false;
	/**
		* Hide button when there is no compare
		*/
	@Input() hasCompare                                                       = false;
	/**
		* Text for the compare label, can be set if translation is needed
		*/
	@Input() compareLabel                                                     = 'Compare';
	/**
		* Flag indicating that we should render stub navitems
		*/
	@Input() renderStubs                                                      = true;
	/**
		* Event that is triggered when the selection is changed.
		*/
	@Output() selectionChanged: EventEmitter<FilterSelectionChangedEventArgs> = new EventEmitter<FilterSelectionChangedEventArgs>();

	/**
		* Event that is triggered when compare mode is changed.
		*/
	@Output() compareModeChanged: EventEmitter<CompareModeChangedEventArgs> = new EventEmitter<CompareModeChangedEventArgs>();

	@ViewChildren(CsAdvancedDropdownComponent) dropdowns: QueryList<CsAdvancedDropdownComponent>;
	@ViewChildren(CsDatepicker) csDatepickers: QueryList<CsDatepicker>;


	isCompareMode = false;
	state         = {};
	mainBar: CompareAndFilterBarDataSource;
	compareBar: CompareAndFilterBarDataSource;

	get compareBarDataSource(): FilterBarDataSource<FilterBarResultParams> {
		return this._compareBarDataSource;
	}

	get mainBarDataSource(): FilterBarDataSource<FilterBarResultParams> {
		return this._mainBarDataSource;
	}

	constructor(private router: Router,
													private route: ActivatedRoute,
													private ngZone: NgZone,
													filterCompareBarService: FilterCompareBarService,
													private i8n: TranslateService,
													private appNavigationService: AppNavigationService,
													public changeRef: ChangeDetectorRef) {
		this._filterCompareBarService = filterCompareBarService;

	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.hasOwnProperty('mDataSource')) {

			this.filterCompareBarService.setMainbar(changes.mDataSource.currentValue);

			this.removeStubs(this.mainBar);
			this.mainBar = this.changeDropdownsAnimated(changes['mDataSource'].currentValue);

			// Makes sure that the initial selection is reflected as QueryParams in the url bar
			// Only update for the initial cahnge MDataSource.
			if (this.SyncUrlParameters && changes.mDataSource.firstChange && changes.mDataSource.currentValue != null) {
				this.SyncUrlWithSelectedItems();
			}
		}
		if (changes.hasOwnProperty('cDataSource')) {
			if (!isNullOrUndefined(changes.cDataSource.currentValue)) {

				this.filterCompareBarService.setCompareBar(changes.cDataSource.currentValue);

				this.removeStubs(this.compareBar);
				this.compareBar    = this.changeDropdownsAnimated(changes['cDataSource'].currentValue, true);
				this.isCompareMode = true;
			} else {
				this.filterCompareBarService.setCompareBar(null);
				this.isCompareMode = false;
				setTimeout(() => {
					this.compareBar = null;
				}, 300);
			}
		}

	}

	generateRow(dataSource: any, isCompareRow = false) {
		if (isNullOrUndefined(dataSource)) {
			return;
		}
		this._filterCompareBarService.hideNoNavigationWarning();

		if (isCompareRow)
			this._compareBarDataSource = dataSource;
		else
			this._mainBarDataSource = dataSource;

		if (!isNullOrUndefined(dataSource.navElements)) {
			dataSource.navElements.map(x => {
														if (x.values.length === 0) {
															x.values.push(
																{
																	'data':  [
																		{
																			'description': '',
																			'isTeaser':    false,
																			'label':       this.i8n.instant('MSSG_NO_NAVBAR_ITEMS'),
																			'identifier':  'Default',
																			'params':      {}
																		}
																	],
																	'label': ''
																}
															);
															this._filterCompareBarService.showNoNavigationWarning();
														}
														return x;
													})
													.forEach(x => {
														x.dropdownType = 'navElement';
														x.isLoading    = false;
													});
		}

		if (!isNullOrUndefined(dataSource.filterElements)) {
			dataSource.filterElements.forEach(x => {
				x.dropdownType = 'filterElement';
				x.isLoading    = false;
			});
		}

		if (isCompareRow) {
			if ((dataSource.navElements.length !== this._mainBarDataSource.navElements.length) ||
				(dataSource.filterElements.length !== this._mainBarDataSource.filterElements.length)) {
				dataSource.navElements.sort((a, b) => {
					const first  = isNullOrUndefined(a.sortIndex)
																				? 0
																				: a.sortIndex;
					const second = isNullOrUndefined(b.sortIndex)
																				? 0
																				: b.sortIndex;

					return first - second;
				});

				const numberToRemove = (dataSource.filterElements.length + dataSource.navElements.length) -
					(this._mainBarDataSource.navElements.length + this._mainBarDataSource.filterElements.length);

				if (numberToRemove > 0) {
					for (let index = numberToRemove; index > 0; index--) {
						if (index < 1)
							break;

						const item = dataSource.navElements[this._mainBarDataSource.filterElements.length + 1 - index];

						item.displayType = 'Hidden';
					}
				}
			}
		}


		let data = dataSource;
		if (dataSource.hasOwnProperty('filterElements')) {
			dataSource.filterElements.forEach(x => x['isComparable'] = true);
			data = [
				{
					'dropdowns': [
																			...dataSource.navElements,
																			...dataSource.filterElements
																		].sort((a, b) => {
						const first  = isNullOrUndefined(a.sortIndex)
																					? 0
																					: a.sortIndex;
						const second = isNullOrUndefined(b.sortIndex)
																					? 0
																					: b.sortIndex;

						return first - second;
					})
				}
			];
		}


		const bar   = new CompareAndFilterBarDataSource(data, isCompareRow);
		bar.state   = 'loading';
		// set default values for the dropdowns, take the first isDefault value
		const items = bar.row;
		for (const dropdown of items.dropdowns) {
			this.state[dropdown.identifier] = dropdown.selectedValue;
		}

		bar.row.dropdowns.forEach((val, index) => {
			if (index >= bar.lastClickedIndex)
				bar.renderedDropDowns.push(val);
		});

		if (bar.renderedDropDowns.length < 5 && this.renderStubs) {
			this.createStubs(5, items, bar);
		}


		return bar;
	}


	onDropdownOpenChanged(event: DropdownOpenStateEventArgs) {

		if (!event.state) {
			return;
		}

		this.csDatepickers.forEach(picker => {
			if (picker._isOpen && event.dropdown.dataSource.identifier !== picker.identifier) {
				picker.close();
			}
		});


		this.dropdowns.forEach(dropdown => {
			if (dropdown.showMenu && event.dropdown.dataSource.identifier !== dropdown.dataSource.identifier) {
				dropdown.toggleMenuState(null);
			}
		});
	}

	onSearchChanged(row: CompareAndFilterBarDataSource, e: SelectionChangedWithSearchEventArgs, dropDowndataSource) {
		e.isCompareRow = row.isCompareRow;
		this.filterCompareBarService.dropdownSearch(e);
	}

	onSelectionChanged(row: CompareAndFilterBarDataSource, e: any, dropDowndataSource) {

		if (!this.appNavigationService.canNavigate()) {
			this.revertToLastSelection();
			return;
		}

		let eventArgs = <FilterSelectionChangedEventArgs>e;

		// Convert the datapicker input
		if (isString(e) || isNumber(e)) {
			const newApiParams                          = {};
			// update apiParams with new selection by combining the id of the drop down and selection
			newApiParams[dropDowndataSource.identifier] = e;

			const dropdownData = {
				'identifier':   dropDowndataSource.identifier,
				'label':        dropDowndataSource.label,
				selectMultiple: false
			};

			eventArgs = new FilterSelectionChangedEventArgs(
				dropdownData,
				[
					new AdvancedDropdownItem({
																															identifier: e,
																															label:      dropDowndataSource.label
																														})
				], newApiParams, row.isCompareRow, dropDowndataSource.dropdownType);
		}

		if (this.SyncUrlParameters) {
			this.SyncUrlWithSelectedItems(eventArgs);
		}

		// cleanup identifier compare name
		const cleanNewApiParams = {};
		for (const key of Object.keys(eventArgs.newApiParams)) {
			const checkFor = '_compare';
			let newKey     = key;
			if (key.endsWith(checkFor))
				newKey = key.replace(checkFor, '');

			cleanNewApiParams[newKey] = eventArgs.newApiParams[key];
		}
		eventArgs.dropdown.sortIndex = dropDowndataSource.sortIndex;
		eventArgs                    = new FilterSelectionChangedEventArgs(
			eventArgs.dropdown, eventArgs.item, cleanNewApiParams, row.isCompareRow, eventArgs.dropdownType);

		// bubble up the event
		this.selectionChanged.emit(eventArgs);

		this.filterCompareBarService.filterbarSelectionChanged.next(eventArgs);
	}

	toggleCompare() {
		if (this.isLoadingCompareBar)
			return;

		let output = !this.isCompareMode;
		if (this.isCompareMode) {
			this.isCompareMode = false;
			output             = false;
		}
		const eventArgs = new CompareModeChangedEventArgs(output);
		this.compareModeChanged.emit(eventArgs);
		this.filterCompareBarService.toggleCompareBarChanged.next(output);
	}

	toggleDropdowns(startFrom: number = 0, state: 'hide' | 'show', isCompareRow = false) {
		const bar          = isCompareRow
																							? this.compareBar
																							: this.mainBar;
		const dropDown     = bar.renderedDropDowns[startFrom];
		dropDown.isLoading = true;

		bar.lastClickedIndex = startFrom + 1;
		bar.state            = 'remove';

		// Retain the current width of the not changing dropdowns
		for (let index = 0; index < bar.lastClickedIndex; index++) {
			const droptElement          = document.querySelector(`.dropdown_${index}`) as HTMLDivElement;
			const width                 = droptElement.getBoundingClientRect().width;
			droptElement.style.maxWidth = width + 'px';
			droptElement.style.minWidth = width + 'px';
		}
		// Removing the loader hard because the angular is thinking it should be shown
		for (let index = bar.lastClickedIndex; index < bar.renderedDropDowns.length; index++) {
			const droptElement         = document.querySelector(`.dropdown_${index} .loader-small-container`) as HTMLDivElement;
			droptElement.style.display = 'none';
		}
		// This must be check
		// bar.renderedDropDowns.splice(bar.lastClickedIndex, bar.renderedDropDowns.length);
		this.changeRef.markForCheck();
		this.changeRef.detectChanges();

	}

	getWidth() {
		const width = 100 / this.mainBar.renderedDropDowns.length;
		return {
			width: width + '%'
		};
	}

	removeStubs(filterBarRef: CompareAndFilterBarDataSource) {
		if (isNullOrUndefined(filterBarRef))
			return;

		filterBarRef.renderedDropDowns.forEach(value => value.isLoading = false);
		filterBarRef.renderedDropDowns = filterBarRef.renderedDropDowns.filter(
			value => value.displayType !== 'Stub' && value.displayType !== 'Hidden');
	}

	isLoadingCompare(state: boolean) {
		this.isLoadingCompareBar = state;
		// if (!this.isLoadingCompareBar)
		//   this.isCompareMode = true;
	}

	toggleAnimationDone($event: any) {
		this.filterCompareBarService.toggleCompareBarComplete.next(this.isCompareMode);
	}

	ngAfterViewInit(): void {
		this.ngZone.runOutsideAngular(
			(): void => {
				const elem = document.querySelector('#compareButton');
				elem.addEventListener('mousedown', (event: MouseEvent) => this.toggleCompare(), false);
			}
		);
	}

	ngOnDestroy(): void {
		this.ngZone.runOutsideAngular(
			(): void => {
				const elem = document.querySelector('#compareButton');
				elem.removeEventListener('mousedown', (event: MouseEvent) => this.toggleCompare(), false);
			}
		);
	}

	dropdownTracking(item: FilterBarItem) {
		if (item == null)
			return;
		return item.identifier;
	}

	dropDownSearchUpdated(searchQuery: string, apiParams: {
																																																								searchOptions: any
																																																							} & {
																																																								[p: string]: string | number
																																																							}, identifier, isCompareRow: boolean, values: KeyValuePair<string, string>[]): void {

		const dropdowns = isCompareRow
																				? this.compareBar
																				: this.mainBar;

		const dropDownToUpdate = this.dropdowns.find(value1 => value1.dataSource.identifier === identifier);


		dropDownToUpdate.updateWithSearchData(values);
	}

	private _mainBarDataSource: FilterBarDataSource<FilterBarResultParams>;
	private _filterCompareBarService: FilterCompareBarService;
	private _compareBarDataSource: FilterBarDataSource<FilterBarResultParams>;

	private changeDropdownsAnimated(filterBarDatasource: FilterBarDataSource<FilterBarResultParams>,
																																	isCompareBar = false) {
		const filterBarRef = this.generateRow(filterBarDatasource, isCompareBar);
		filterBarRef.state = 'loaded';
		if (filterBarRef.lastClickedIndex) {
			const dropDown     = filterBarRef.renderedDropDowns[filterBarRef.lastClickedIndex - 1];
			dropDown.isLoading = false;
		}

		setTimeout(() => {
			for (let index = 0; index < filterBarRef.lastClickedIndex; index++) {
				const droptElement          = document.querySelector(`.dropdown_${index}`) as HTMLDivElement;
				droptElement.style.maxWidth = '100%';
			}
		}, 0);
		return filterBarRef;
	}

	/**
		* Syncs the UrlBar with the selected items in the filterbar as QueryParams.
		* @param eventArgs
		* @constructor
		* @private
		*/
	private SyncUrlWithSelectedItems(eventArgs: FilterSelectionChangedEventArgs = null) {
		const patchedQueryParams = {};

		if (eventArgs !== null) {
			const values = eventArgs.item.map(item => item.identifier == null
																																													? 'null'
																																													: item.identifier);

			if (eventArgs.dropdownType === 'navElement') {
				(patchedQueryParams as any).pageViewSelection = null;
			}

			if (eventArgs.dropdown.selectMultiple) {
				this.state[eventArgs.dropdown.identifier] = values; // `[${values.join(',')}]`;

			} else {
				// this wil result in a array when you pass a array to the queryparam.
				// so set the single selected value as value not the wrapping array
				this.state[eventArgs.dropdown.identifier] = values.length === 1
																																																? values[0]
																																																: values;
			}
		}

		for (const key of Object.keys(this.state)) {
			patchedQueryParams[key] = this.state[key] == null
																													? 'null'
																													: this.state[key];
		}

		// What until angular is done with any leftover work, then update url params. this makes sure that the navigation id
		this.ngZone.onStable.pipe(take(1))
						.subscribe(() => {

							// clean url with params that are not in the patchedQueryParams
							if (!isEmptyObject(patchedQueryParams)) {
								const cleaningQueryParams = {};
								const params              = flattenObject(patchedQueryParams);
								Object.keys(this.route.snapshot.queryParams)
														.forEach(urlParamKey => {
															if (params[urlParamKey] == null)
																cleaningQueryParams[urlParamKey] = null;
														});
								this.router.navigate([], {
									queryParams:         {...cleaningQueryParams, ...params},
									relativeTo:          this.route,
									queryParamsHandling: 'merge'
								});
							}

						});

	}

	private createStubs(amountOfStubs: number, items: any, bar: CompareAndFilterBarDataSource) {
		items.stubs = [];
		while (bar.renderedDropDowns.length !== amountOfStubs) {
			bar.renderedDropDowns.push({displayType: 'Stub', identifier: 'stub'} as any);
		}
	}

	private setQueryParamsAsDefaultDropdownValues() {
		if (!this.SyncUrlParameters) {
			return;
		}

		for (const dropdownIdentifier in this.route.snapshot.params) {
			if (this.route.snapshot.params.hasOwnProperty(dropdownIdentifier)) {
				const dropdownValue            = this.route.snapshot.params[dropdownIdentifier];
				this.state[dropdownIdentifier] = dropdownValue;

				if (this.isCompareMode === false && dropdownIdentifier.endsWith('_compare')) {
					this.toggleCompare();
				}
			}
		}
	}

	/**
		* Revert the current selection to the state before changing the selection
		*/
	private revertToLastSelection() {
		this.mainBar = this.changeDropdownsAnimated(this.mDataSource);
		SafeMethods.detectChanges(this.changeRef);
	}
}
