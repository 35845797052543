import { HttpErrorResponse, HttpParams }     from '@angular/common/http';
import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional, SkipSelf, ViewChild
}                                            from '@angular/core';
import {
	ActivatedRoute, DefaultUrlSerializer, NavigationStart, Router, RouterOutlet
}                                            from '@angular/router';
import {
	AppNavigationService, CsGenericErrorLogger, routerFadeTransition, SafeMethods
}                                            from '@cs/common';
import {
	FilterSelectionChangedEventArgs
}                                            from '@cs/components/advanced-dropdown';
import {
	CsFilterAndCompareBarComponent, FilterBarDataSource, FilterBarResultParams, FilterCompareBarQuery, FilterCompareBarService,
	SubFilterItem, ViewSelectionItem
}                                            from '@cs/components/filter-and-compare-bar';
import {
	CsHttp400ErrorResponse, CsHttpErrorResponse, CsHttpRequestOptions, flattenObject, getPropertyOf, isNullOrUndefined, isString,
	KeyValuePair, LoggerUtil, restoreFlattenObject, toCamelCase, toKebabCase
}                                            from '@cs/core';
import {
	AppQuery, AppService, DynamicButton, ErrorService, PmNavbarExtendedSelection
}                                            from '@cs/performance-manager/shared';
import { getUIPlaceholder, isUIPlaceholder } from '@cs/common/culture';
import {
	UntilDestroy, untilDestroyed
}                                            from '@ngneat/until-destroy';
import {
	TranslateService
}                                            from '@ngx-translate/core';
import { Subscription }                      from 'rxjs';

import { debounceTime, filter, takeWhile }   from 'rxjs/operators';
import { FilterAndNavbarShellConfigService } from './filter-and-navbar-shell-config.service';


@UntilDestroy()
@Component({
												selector:        'pmc-filter-and-navbar-shell',
												templateUrl:     './filter-and-navbar-shell.component.html',
												styleUrls:       ['./filter-and-navbar-shell.component.scss'],
												changeDetection: ChangeDetectionStrategy.OnPush,
												animations:      [routerFadeTransition('fadeAnimation')]
											})
export class FilterAndNavbarShellComponent implements OnInit,
																																																						OnDestroy {

	/**
		* Instance of the FilterAndCompareComponent used for interacting directly with the component
		*/
	@ViewChild(CsFilterAndCompareBarComponent)
	filterAndCompareBar: CsFilterAndCompareBarComponent;

	dynamicButtons: DynamicButton[];

	/**
		* Datasource for the first filter navbar
		*/
	mainbarDataSource: FilterBarDataSource<FilterBarResultParams> = {
		activateComparison: false,
		filterElements:     [],
		navElements:        [],
		apiParams:          null,
		resultParams:       null,
		subFilterItems:     []
	};

	/**
		* Datasource for the compare bar. this is not alway present
		*/
	comparebarDataSource: FilterBarDataSource<FilterBarResultParams>;

	/**
		* Current selection of the FilterNavbar
		*/
	currentNavbarSelection: PmNavbarExtendedSelection;

	/**
		* Flag indicating if the selection has a compare option
		*/
	showCompareButton$ = this.filterAndCompareBarQuery.select(store => store.hasComparison);

	/**
		* Show informationlabel on the subfilters row
		*/
	informationLabel$ = this.appStateQuery.select(store => store.informationLabel);

	/**
		* Current request handeling a navbar update
		*/
	currentRequest: Subscription;

	/**
		* Allows components to set the classes for the filter and navbar shell
		*/
	filterbarShellClasses$ = this.filterAndNavbarShellConfigService.activeFilterAndNavbarShellClasses$
																														.asObservable()
																														.pipe(untilDestroyed(this));
	showNavigationWarning  = false;

	constructor(private filterAndNavbarShellConfigService: FilterAndNavbarShellConfigService,
													private appStateQuery: AppQuery,
													private appStateService: AppService,
													private router: Router,
													private activatedRoute: ActivatedRoute,
													private filterAndCompareBarService: FilterCompareBarService,
													private filterAndCompareBarQuery: FilterCompareBarQuery,
													private appNavigationService: AppNavigationService,
													private changeRef: ChangeDetectorRef,
													@Optional() private loggerService: CsGenericErrorLogger,
													private errorHandlerService: ErrorService,
													@Inject(TranslateService) @SkipSelf() private translateService: TranslateService) {
	}

	ngOnDestroy(): void {}

	ngOnInit() {
		this.setupNavbarChangeListener();
		this.setupToggleCompareBarButtonListener();
		this.setupNavigationRequestHandler();
		this.setupPrevNextNavigation();
		this.setupQueryParamProcessing();
		this.setupNavigationWarning();
		this.setupSearchDropdown();

		// This should be filled by the server
		this.dynamicButtons = null;
	}

	searchChanged($event: FilterSelectionChangedEventArgs) {}

	filterBarSelectionChanged($event: FilterSelectionChangedEventArgs) {

		this.filterAndCompareBar.toggleDropdowns($event.dropdown.sortIndex, 'hide', $event.isCompareRow);

		this.changeRef.detectChanges();

		const dataSource = $event.isCompareRow
																					? this.comparebarDataSource
																					: this.mainbarDataSource;

		const apiParams = Object.assign({}, dataSource.apiParams);

		if ($event.dropdownType === 'navElement' && dataSource.subFilterItems != null) {
			dataSource.subFilterItems.forEach(value => {
				if (apiParams.hasOwnProperty(value.id))
					delete apiParams[value.id];
			});
		}
		const selection = Object.assign({}, apiParams, $event.newApiParams);

		if ($event.dropdownType === 'navElement') {
			this.comparebarDataSource = null;
		}

		this.updateFilterAndCompareBar(selection, $event.dropdown.identifier, $event.isCompareRow);
	}


	dismissSubFilter(subFilterItem: SubFilterItem) {

		const {mainbarApiParams}  = this.filterAndCompareBarQuery.getValue();
		const removal             = {};
		removal[subFilterItem.id] = null;
		const selection           = Object.assign({}, mainbarApiParams, removal);

		this.updateFilterAndCompareBar(selection, subFilterItem.id, false);

		// Update url when dismissing SubFilter
		const urlSerializer = new DefaultUrlSerializer();
		const urlTree       = urlSerializer.parse(this.router.url);
		const params        = urlTree.queryParams;
		if (!isNullOrUndefined(params[subFilterItem.id])) {
			delete params[subFilterItem.id];

			this.router.navigate(
				[],
				{
					relativeTo:  this.activatedRoute,
					queryParams: params
				}
			);
		}
	}

	pageViewSelectionChanged(item: ViewSelectionItem, option: KeyValuePair<any, any>) {
		const {mainbarApiParams} = this.filterAndCompareBarQuery.getValue();
		this.router.navigate([], {
							queryParams:         {pageViewSelection: btoa(JSON.stringify({[item.id]: option.key}))},
							queryParamsHandling: 'merge'
						})
						.then(value => {
							this.updateFilterAndCompareBar({...mainbarApiParams, [item.id]: option.key}, item.id);
						});

	}


	public getRouterOutletState(outlet: RouterOutlet) {
		return outlet.isActivated
									? outlet.activatedRoute
									: '';
	}

	/**
		* Allows the filterbar to store objects as a queryparams
		*/
	private setupQueryParamProcessing() {
		this.appNavigationService.registerPreProcessAction(params => flattenObject(params));
		this.appNavigationService.registerCleanUpAction(params => restoreFlattenObject(params));
	}

	private setupNavigationRequestHandler() {
		this.filterAndCompareBarService.filterbarNavigationRequested
						.pipe(untilDestroyed(this))
						.subscribe(newApiParams => {
							const {mainbarApiParams} = this.filterAndCompareBarQuery.getValue();
							const selection          = Object.assign({}, mainbarApiParams, newApiParams);
							const patchedParams      = this.appNavigationService.preProcessQueryParams(selection);
							this.router.navigate([], {
												queryParams:         patchedParams,
												relativeTo:          this.activatedRoute,
												queryParamsHandling: '',
												skipLocationChange:  true
											})
											.then(value => {
												this.updateFilterAndCompareBar(selection, 'NavigationRequested', false, {}, true);
											});
						});
	}

	private setupToggleCompareBarButtonListener() {
		this.filterAndCompareBarService.toggleCompareBarChanged
						.pipe(untilDestroyed(this))
						.subscribe(value => {
							if (value) {
								this.filterAndCompareBar.isLoadingCompare(true);
								const selection = this.filterAndCompareBarQuery.getValue().mainbarApiParams;
								this.updateFilterAndCompareBar({}, 'CompareButtonToggle', true, selection);
							} else {
								this.comparebarDataSource = null;
							}
						});
	}

	private setupNavbarChangeListener() {
		this.appStateQuery.select(store => store.currentAppParamsScope)
						.pipe(
							untilDestroyed(this),
							filter(value => !isNullOrUndefined(value))
						)
						.subscribe(value => {
							// collapse the comparebar
							this.comparebarDataSource = null;

							this.currentNavbarSelection = value as PmNavbarExtendedSelection;
							const queryParams           = {...this.activatedRoute.snapshot.queryParams};

							for (const key of Object.keys(queryParams)) {
								const qValue = queryParams[key];
								if (isString(qValue) && qValue.indexOf('[') > -1) {
									let sValue: string = qValue;
									sValue             = sValue.replace('[', '')
																																				.replace(']', '');
									queryParams[key]   = sValue.toString()
																																				.split(',');
								}
							}

							// TODO: filter the queryParams for the compare bar
							this.updateFilterAndCompareBar(
								{
									...queryParams,
									...value
								}, 'AppParamsScope');
						});
	}

	private setupPrevNextNavigation() {

		this.router.events.forEach((event) => {
			if (event instanceof NavigationStart) {
				if (event.restoredState) {
					const urlSerializer = new DefaultUrlSerializer();
					const urlTree       = urlSerializer.parse(event.url);
					const selection     = urlTree.queryParams;
					this.updateFilterAndCompareBar(selection, 'NavigationBackForward');
				}
			}
		});

	}

	private updateFilterAndCompareBar(apiParams: {
																																				[key: string]: any
																																			}                        = {},
																																			trigger: string          = '',
																																			isComparisonBar: boolean = false,
																																			paramsMain: {
																																				[key: string]: any
																																			}                        = {},
																																			navigateTo               = true) {

		if (!isNullOrUndefined(this.currentRequest) && !this.currentRequest.closed)
			this.currentRequest.unsubscribe();

		// merge the module params with the params provided by the filterbar selection
		let patchedParams = Object.assign({}, apiParams, this.currentNavbarSelection);

		// Other feature modules could register a cleanup method for the navigation params,
		// mostly used for nested objects as base64 queryparam
		patchedParams = this.appNavigationService.cleanUpQueryParams(patchedParams) as PmNavbarExtendedSelection;

		const options                = new CsHttpRequestOptions();
		options.errorResponseHandler = (error) => {
			switch (error.status) {
				case 400:
					try {
						console.log(JSON.stringify({
																																		status_code: error.status,
																																		url:         error.url,
																																		param:       this.activatedRoute.snapshot.params as HttpParams,
																																		data:        {
																																			params:     patchedParams,
																																			paramsMain: paramsMain,
																																			trigger:    trigger,
																																			navbar:     this.currentNavbarSelection.navFilterBar
																																		},
																																		headers:     error.headers,
																																		method:      'POST'
																																	}));
						this.loggerService.logError(new Error('Filternavbar resulted in a 400, this should not happen'))
										.subscribe(value => {
										});

						this.resetDashboard(error);
					} catch (e) {
						this.resetDashboard(error);
					}
					return true;
			}
			return false;
		};

		this.currentRequest = this.filterAndNavbarShellConfigService.getFilterAndCompareBarData(
																													patchedParams,
																													this.currentNavbarSelection.navFilterBar,
																													isComparisonBar,
																													paramsMain,
																													trigger,
																													options
																												)
																												.pipe(untilDestroyed(this))
																												.subscribe(result => {
																													if (isNullOrUndefined(result.value)) {
																														LoggerUtil.error('PLEASE CHECK FILTERBAR request... it\'s returning NULL');
																														return;
																													}

																													if (trigger === '' && !isNullOrUndefined(this.filterAndCompareBar)) {
																														const currentBar = isComparisonBar
																																																	? this.filterAndCompareBar.compareBar
																																																	: this.filterAndCompareBar.mainBar;
																														if (!isNullOrUndefined(currentBar)) {
																															currentBar.state = 'firstLoaded';
																															this.filterAndCompareBar.changeRef.markForCheck();
																															this.filterAndCompareBar.changeRef.detectChanges();
																														}
																													}
																													this.patchNavbarWithTranslations(result.value);
																													if (isComparisonBar) {
																														this.comparebarDataSource = result.value;
																														this.filterAndCompareBar.isLoadingCompare(false);
																														this.filterAndCompareBarService.notifyCompareBarIsLoaded(this.comparebarDataSource);
																													} else {
																														this.mainbarDataSource = result.value;

																														const pageType = this.mainbarDataSource.resultParams.pageType;
																														const pageName = this.mainbarDataSource.resultParams.pageName;

																														this.filterAndCompareBarService.toggleCompareButton(
																															getPropertyOf(this.mainbarDataSource.resultParams, 'hasComparison', false)
																														);

																														if (!navigateTo) {
																															SafeMethods.detectChanges(this.changeRef);
																															return;
																														}

																														switch (pageType) {
																															case 'DataEntryGrid':
																																this.router.navigate(['data-entry'], {
																																					relativeTo:          this.activatedRoute,
																																					queryParamsHandling: 'preserve'
																																				})
																																				.then(value => this.filterAndCompareBarService.setMainbar(this.mainbarDataSource));
																																break;
																															case 'Page':
																																this.router.navigate([toKebabCase(pageName)], {
																																					relativeTo:          this.activatedRoute,
																																					queryParamsHandling: 'preserve'
																																				})
																																				.then(value => this.filterAndCompareBarService.setMainbar(this.mainbarDataSource));
																																break;
																															case 'TeaserPage':
																																this.router.navigate(['iframe-teaser'], {
																																					relativeTo:          this.activatedRoute,
																																					queryParamsHandling: 'preserve'
																																				})
																																				.then(value => this.filterAndCompareBarService.setMainbar(this.mainbarDataSource));
																																break;
																														}
																													}

																													SafeMethods.detectChanges(this.changeRef);
																												});
	}

	/**
		* Remove the invalid parameters from the url
		* @param error Validation response
		*/
	private cleanUpInvalidQueryParams(error: CsHttp400ErrorResponse) {
		if (error.errors === undefined)
			return;

		// Update url when dismissing SubFilter
		const urlSerializer = new DefaultUrlSerializer();
		const urlTree       = urlSerializer.parse(this.router.url);
		const params        = urlTree.queryParams;

		for (const key of Object.keys(error.errors)) {
			const keyCamelCase = toCamelCase(key);
			if (!isNullOrUndefined(params[keyCamelCase])) {
				delete params[keyCamelCase];
			}
		}

		this.router.navigate(
			[],
			{
				relativeTo:  this.activatedRoute,
				queryParams: params
			}
		);


	}

	private resetDashboard(error: HttpErrorResponse) {
		const nameObject = {};
		[...this.mainbarDataSource.navElements, ...this.mainbarDataSource.filterElements]
			.forEach(value => nameObject[value.identifier.toLowerCase()] = value.label);


		this.errorHandlerService.handleBadRequest(error as CsHttpErrorResponse, nameObject);
		// do a clean request i case of a invalid error. Because otherwise user will
		// have a broken interface and a error message.
		this.cleanUpInvalidQueryParams(error.error as CsHttp400ErrorResponse);
		this.updateFilterAndCompareBar();
	}

	/**
		* Implement the translation in the client because of the lack for translation provided by the CF server... 👷‍♂️
		* @param value Navbar data structure
		* @private
		*/
	private patchNavbarWithTranslations(value: FilterBarDataSource<FilterBarResultParams>) {
		const all = [...(value.navElements ?? []), ...(value.filterElements ?? [])];

		for (const item of all) {
			item.label = isUIPlaceholder(item.label)
																? getUIPlaceholder(item.label, this.translateService)
																: item.label;
			item.values.forEach(group => {

				group.label = isUIPlaceholder(group.label)
																		? getUIPlaceholder(group.label, this.translateService)
																		: group.label;

				group.data.forEach(data => {
					data.label = isUIPlaceholder(data.label)
																		? getUIPlaceholder(data.label, this.translateService)
																		: data.label;
				});
			});
		}


	}

	private setupNavigationWarning() {
		this.filterAndCompareBarService.toggleNavigationWarning
						.pipe(untilDestroyed(this))
						.subscribe(toggleValue => {
							this.showNavigationWarning = toggleValue;
						});
	}

	private setupSearchDropdown(): void {
		this.filterAndCompareBarService.filterbarSearchChanged.pipe(debounceTime(400))
						.pipe(untilDestroyed(this),
												takeWhile($event => $event.searchQuery !== ''))
						.subscribe($event => {
							const dataSource             = $event.isCompareRow
																																						? this.comparebarDataSource
																																						: this.mainbarDataSource;
							const apiParams              = Object.assign({}, {searchOptions: $event.dropdown.searchOptions}, dataSource.apiParams);
							const options                = new CsHttpRequestOptions();
							options.errorResponseHandler = (error) => {
								switch (error.status) {
									case 400:
										try {
											// 	console.log(JSON.stringify({
											// 																													status_code: error.status,
											// 																													url:         error.url,
											// 																													param:       this.activatedRoute.snapshot.params as HttpParams,
											// 																													data:        {
											// 																														params:     patchedParams,
											// 																														paramsMain: paramsMain,
											// 																														trigger:    value.dropdown.identifier,
											// 																														navbar:     this.currentNavbarSelection.navFilterBar
											// 																													},
											// 																													headers:     error.headers,
											// 																													method:      'POST'
											// 																												}));
											this.loggerService.logError(new Error('Filternavbar resulted in a 400, this should not happen'))
															.subscribe(value => {
															});


										} catch (e) {

										}
										return true;
								}
								return false;
							};
							this.filterAndNavbarShellConfigService.dropdownSearch($event.searchQuery,
																																																													apiParams,
																																																													$event.dropdown.identifier,
																																																													$event.isCompareRow, {}, $event.dropdown.identifier, options)
											.pipe(untilDestroyed(this))
											.subscribe(value => {
												console.log(value.value);

												this.filterAndCompareBar.dropDownSearchUpdated($event.searchQuery,
																																																											apiParams,
																																																											$event.dropdown.identifier,
																																																											$event.isCompareRow, value.value);
											});
						});
	}
}
