import {FilterBarElementValue} from './filter-bar-element-data';

export type FilterBarRenderType = 'Default' | 'DatePicker' | 'Stub' | 'Hidden';

export class FilterBarItem {
    displayType: FilterBarRenderType;
    identifier: string;
    label: string;
    requireReload: boolean;
    search: { searchEndPoint: boolean, hasSearchBar: boolean };
    selectedValue: string | number;
    sortIndex: number;
    values: FilterBarElementValue[];
    isLoading: boolean;
    isMultiSelect: boolean;

    constructor(init: Partial<FilterBarItem> = {}) {
        Object.assign(this, init);
    }
}
